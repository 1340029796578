
import configJs from "@/config.js";
export const baseUrl = configJs.baseUrl
export const wsUrl = 'wss://gpt.v-wim.xyz'

export const HTTP_URI = {
  BASEURL: baseUrl,
  sendMessage:'/resource/sms/code',
  login:'/auth/login',
  notice:'/announcement/list', //公告
  logout:'/auth/logout',
  written :'/ocr/answer',  //笔试文件上传
  help :'/helpInfo/list',  //帮助
  userInfo :'/membershipUser/info',  //我的查询
  user :'/membershipUser',  //我的修改
  aaaa :'/aaaaaaa',  //历史记录
  optimiseResume :'/ocr/optimiseResume',  //简历优化
  
}
export default { HTTP_URI, baseUrl }
