<template>
  <el-dialog title="帮助" :visible.sync="dialogVisibles" :before-close="handleClose">
    <div>
      <video class="video" ref="video" :src="videoUrl" controls>
        你的浏览器不支持
      </video>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisibles: false,
      videoUrl: ''
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    open() {
      this.dialogVisibles = true
    },
    handleClose() {
      this.$refs.video.pause()
      this.dialogVisibles = false
    },
    handleConfirm() {
      this.$refs.video.pause()
      this.dialogVisibles = false
    },
    getData() {
      this.$https.get('help', this.form).then(res => {
        this.videoUrl = res.rows.length > 0 ? res.rows[0].content : ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
}
</style>