// 应用全局配置
let baseUrl = ''

if (process.env.NODE_ENV === 'development') {
	baseUrl = '/api/'
} else if (process.env.NODE_ENV === 'production') {
	baseUrl = 'https://admin-ai.yiyoujiankang.com/prod-api/'

}
module.exports = {
	// 讯飞
	xunfei: {
		// APPID: "a5240cc4",
		// API_KEY: "00735ba252351275da0308a796cabb98",
		APPID: "7ddda20c",
		API_KEY: "19be2b22dd0e2e19d4739926baf9e312",
	},
	baseUrl,
	// baseUrl: 'http://localhost:8080',
	// 客户端key
	grantType: "sms",
	// 客户端id
	clientId: "78d1b39ee4e3459fcd1eba51ed6f0614",
	// 接口加密传输 RSA 公钥与后端解密私钥对应 如更换需前后端一同更换
	VITE_APP_RSA_PUBLIC_KEY: 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKoR8mX0rGKLqzcWmOzbfj64K8ZIgOdHnzkXSOVOZbFu/TJhZ7rFAN+eaGkl3C4buccQd/EjEsj9ir7ijT7h96MCAwEAAQ==',
	// 接口响应解密 RSA 私钥与后端加密公钥对应 如更换需前后端一同更换
	VITE_APP_RSA_PRIVATE_KEY: 'MIIBVAIBADANBgkqhkiG9w0BAQEFAASCAT4wggE6AgEAAkEAmc3CuPiGL/LcIIm7zryCEIbl1SPzBkr75E2VMtxegyZ1lYRD+7TZGAPkvIsBcaMs6Nsy0L78n2qh+lIZMpLH8wIDAQABAkEAk82Mhz0tlv6IVCyIcw/s3f0E+WLmtPFyR9/WtV3Y5aaejUkU60JpX4m5xNR2VaqOLTZAYjW8Wy0aXr3zYIhhQQIhAMfqR9oFdYw1J9SsNc+CrhugAvKTi0+BF6VoL6psWhvbAiEAxPPNTmrkmrXwdm/pQQu3UOQmc2vCZ5tiKpW10CgJi8kCIFGkL6utxw93Ncj4exE/gPLvKcT+1Emnoox+O9kRXss5AiAMtYLJDaLEzPrAWcZeeSgSIzbL+ecokmFKSDDcRske6QIgSMkHedwND1olF8vlKsJUGK3BcdtM8w4Xq7BpSBwsloE=',
	// 应用信息
	appInfo: {
		// 应用名称
		name: "AI",
		// 应用版本
		version: "1.1.0",
		// 应用logo
		logo: "/static/logo.png",
		// 官方网站
		site_url: "http://ruoyi.vip",
		// 政策协议
		agreements: [{
			title: "隐私政策",
			url: "https://ruoyi.vip/protocol.html"
		},
		{
			title: "用户服务协议",
			url: "https://ruoyi.vip/protocol.html"
		}
		]
	}
}