import axios from 'axios'
import { Notification, Message, Loading } from 'element-ui'
import cache from './cache'
import { HTTP_URI } from '../api/api'
import configJs from "@/config.js";
import {
  encryptBase64,
  encryptWithAes,
  generateAesKey,
} from '@/utils/crypto';
import {
  encrypt,
} from '@/utils/jsencrypt';
import store from "../store/index";
import router from "../router";

const clientId = configJs.clientId

let loading
// loading开始 方法
function startLoading() {
  // element-ui loading 服务调用方式
  loading = Loading.service({
    customClass: 'loading_icon',
    fullscreen: true,
    lock: true,
    text: '加载中...',
    spinner: 'el-icon-loading', // 自定义图标
    background: 'rgba(255, 255, 255, 0.1)'
  })
}
// loading结束 方法
function endLoading() {
  loading.close()
}

// 定义一个变量，有请求变量加一，收到响应变量减一
let loadingCount = 0
/**
 * 调用一次startLoadingAddCount() 方法 开启Loading 并 loadingCount + 1
 * 调用一次endLoadingSubCount() 方法 loadingCount - 1 直到为0 关闭loading
 */
export function startLoadingAddCount() {
  if (loadingCount === 0) {
    startLoading()
  }
  loadingCount++
}

export function endLoadingSubCount() {
  loadingCount--
  if (loadingCount === 0) {
    endLoading()
  }
}
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: HTTP_URI.BASEURL,
  // 超时
  timeout: 300000
})

// request拦截器
service.interceptors.request.use(
  config => {
    startLoadingAddCount()
    // debugger
    // console.log('request', config);

    // 是否需要token，默认需要
    const isToken = (config.headers || {}).isToken === false;
    // 是否需要防止数据重复提交 默认不需要
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
    const token = window.localStorage.getItem('token')
    if (token && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + token
    }

    // get请求映射params参数
    // if (config.method === 'get' && config.params) {
    //   let url = config.url + '?' + tansParams(config.params);
    //   url = url.slice(0, -1);
    //   config.params = {};
    //   config.url = url;
    // }

    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
      // const datas = config.data
      // const data = Object.keys(datas).map(item => {
      //   if (datas[item] === '' || datas[item] == null) delete datas[item]
      // })
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime()
      };
      const sessionObj = cache.session.getJSON('sessionObj');
      if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
        cache.session.setJSON('sessionObj', requestObj);
      } else {
        const s_url = sessionObj.url; // 请求地址
        const s_data = sessionObj.data; // 请求数据
        const s_time = sessionObj.time; // 请求时间
        const interval = 500; // 间隔时间(ms)，小于此时间视为重复提交
        if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
          const message = '数据正在处理，请勿重复提交';
          console.warn(`[${s_url}]: ` + message);
          return Promise.reject(new Error(message));
        } else {
          cache.session.setJSON('sessionObj', requestObj);
        }
      }
    }

    config.headers['clientId'] = clientId

    // 是否需要加密 默认不需要加密
    const isEncrypt = (config.headers || {}).isEncrypt === 'true';
    // 当开启参数加密
    if (isEncrypt && (config.method === 'post' || config.method === 'put')) {
      // 生成一个 AES 密钥
      const aesKey = generateAesKey();
      config.headers['encrypt-key'] = encrypt(encryptBase64(aesKey));
      config.data = typeof config.data === 'object' ? encryptWithAes(JSON.stringify(config.data), aesKey) : encryptWithAes(config.data, aesKey);
    }

    // FormData数据去请求头Content-Type
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }
    // console.log('config', config);

    return config
  },
  error => {
    endLoadingSubCount()
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  res => {
    endLoadingSubCount()
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = res.data.message || res.data.msg
    if (code === 401) {
      Notification.error({
        title: msg
      })
      store.dispatch('logout').then(() => {
        setTimeout(() => {
          router.push('/login')
        }, 1500)
      })
      return Promise.reject('error')
    } else if (code !== 200) {
      Notification.error({
        title: msg
      })
    } else {
      return res.data
    }
  },
  error => {
    endLoadingSubCount()
    let { message } = error
    if (message === 'Network Error') {
      message = '网络连接错误'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export default service
