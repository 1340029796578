<template>
  <el-dialog title="我的" :visible.sync="dialogVisibles" :before-close="handleClose">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-form-item label="用户名" :label-width="formLabelWidth" prop="userName">
        <el-input v-model="form.userName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="手机号" :label-width="formLabelWidth" prop="phoneNo">
        <el-input v-model="form.phoneNo" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisibles: false,
      form: {
        id:null,
        userName: '',
        phoneNo: '',
      },
      formLabelWidth: '80px',
      rules: {
        userName: [{ required: false, message: '请输入用户名', trigger: 'change' }],
        phoneNo: [{ required: true, message: '请输入手机号', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.form.phoneNo = this.$store.getters.phone
    this.getData()
  },
  methods: {
    open() {
      this.dialogVisibles = true
    },
    handleConfirm() {
      let that = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$https.put('user', this.form).then(res => {
            this.$message.success('修改成功！')
            setTimeout(() => {
              that.handleClose()
            }, 1500)
          })
        }
      })
    },
    handleClose() {
      this.dialogVisibles = false
    },
    getData() {
      this.$https.get('userInfo',{phoneNo:this.form.phoneNo}).then(res => {
        this.form.userName = res.data.userName
        this.form.id = res.data.id
        this.$store.commit('SET_USERNAME',res.data.userName)
      })
    }
  }
}
</script>

<style></style>