import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: window.localStorage.getItem('token'),
    phone: window.localStorage.getItem('phone'),
    userName: ''
  },
  getters,
  mutations: {
    SET_TOKEN: (state, data) => {
      state.token = data
      window.localStorage.setItem('token', data)
    },
    SET_PHONE: (state, data) => {
      state.phone = data
      window.localStorage.setItem('phone', data)
    },
    SET_USERNAME: (state, data) => {
      state.userName = data
    },
  },
  actions: {
    async login({ commit }, data) {
      try {
        let { REQUEST } = await import('@/api/http')
        const res = await REQUEST.post('login', data.from, {
          isToken: data.isToken,
          isEncrypt: data.isEncrypt,
        })
        commit('SET_TOKEN', res.data.access_token);
        commit('SET_PHONE', data.from.phonenumber);
      } catch (err) {
        console.error('There was an error:', err);
      }
    },
    async logout({ commit }, data) {
      try {
        let { REQUEST } = await import('@/api/http')
        const res = await REQUEST.post('logout');
        commit('SET_TOKEN', '');
        commit('SET_PHONE', '');
        window.localStorage.clear()
      } catch (err) {
        console.error('There was an error:', err);
      }
    },
    async writtenSubmit({ commit }, data) {
      let formData = new FormData();
      formData.append('file', data.file);

      try {
        let { REQUEST } = await import('@/api/http')
        const res = await REQUEST.post('written', formData);
        // 假设 res.data.remainingTimes 是你需要的数据
        commit('SET_TOTAL', res.data.remainingTimes);
      } catch (err) {
        console.error('There was an error submitting the file:', err);
      }
    },

  },
  modules: {},
  plugins: [
    // createPersistedState({
    //   storage: window.sessionStorage
    // })
  ]
})
export default store;
